import React, { useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Box,
  Typography,
  Paper,
  TextField,
  Button,
  Alert,
  CircularProgress,
  IconButton,
  InputAdornment
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

function ResetPassword() {
  const [formValues, setFormValues] = useState({
    password: '',
    confirmPassword: '', 
  });
  const [showPasswords, setShowPasswords] = useState(false); // Global toggle for password visibility
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const { token } = useParams();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setMessage('');
    setError('');

    // Check if passwords match
    if (formValues.password !== formValues.confirmPassword) {
      setError("Passwords do not match");
      setLoading(false);
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/reset-password/reset/${token}`,
        { password: formValues.password }
      );
      setMessage(response.data); 
      setTimeout(() => {
        navigate('/login');
      }, 3000);
    } catch (err) {
      console.error('Error resetting password:', err);
      setMessage('Error resetting password.');
    } finally {
      setLoading(false);
    }
  };

  // Global toggle function for password visibility
  const handleTogglePasswords = () => setShowPasswords(!showPasswords);

  return (
    <Box
      sx={{
        width: { xs: '90%', sm: '80%', md: '60%', lg: '50%', xl: '40%' },
        maxWidth: 500,
        mx: 'auto',
        mt: 2,
        p: 0,
      }}
    >
      <Paper elevation={3} sx={{ p: 2 }}>
        {/* Title and Visibility Toggle Container */}
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}>
          <Typography variant="h4" component="h1">
            Reset Password
          </Typography>
          <IconButton onClick={handleTogglePasswords} edge="end" sx={{mr:-1}}>
            {showPasswords ? <VisibilityOff /> : <Visibility />}
          </IconButton>
        </Box>

        <form onSubmit={handleSubmit}>
          <TextField
            label="New Password"
            type={showPasswords ? 'text' : 'password'}
            value={formValues.password}
            fullWidth
            required
            onChange={(e) =>
              setFormValues({ ...formValues, password: e.target.value })
            }
            sx={{ mb: 2 }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {/* Toggle removed here since it's at the title level */}
                </InputAdornment>
              ),
            }}
          />
          <TextField
            label="Confirm Password"
            type={showPasswords ? 'text' : 'password'}
            value={formValues.confirmPassword}
            fullWidth
            required
            onChange={(e) =>
              setFormValues({ ...formValues, confirmPassword: e.target.value })
            }
            sx={{ mb: 2 }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {/* Toggle removed here since it's at the title level */}
                </InputAdornment>
              ),
            }}
          />
          {error && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {error}
            </Alert>
          )}
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            disabled={loading}
          >
            {loading ? (
              <CircularProgress size={24} sx={{ color: 'white' }} />
            ) : (
              'Reset Password'
            )}
          </Button>
        </form>
        {message && (
          <Alert
            severity={message === 'Error resetting password.' ? 'error' : 'success'}
            sx={{ mt: 2 }}
          >
            {message}
          </Alert>
        )}
      </Paper>
    </Box>
  );
}

export default ResetPassword;
import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import { useAuth } from '../../contexts/AuthContext';
import { useLayout } from '../../contexts/LayoutContext';

import apiService from '../../services/apiService';
import AuthWrapper from '../AuthWrapper';
import LeadsFiltersComponent from './LeadsFiltersComponent';
import LeadsListComponent from './LeadsListComponent';
import MobileView from './MobileView';
import DesktopView from './DesktopView';
import ExpandedLead from './ExpandedLead';
import Dashboard from '../dashboard/Dashboard';
function LeadsList() {
  const {
    user,
    setUser,
    getAuthHeader,
    availableLeads,
    setAvailableLeads,
    availableLeadsCount,
    setAvailableLeadsCount,
    setPurchasedLeads,
  } = useAuth();

  const { isMobile } = useLayout();
  const initialLoadCount = 25;

  const [filters, setFilters] = useState({
    search: '',
    state: 'All',
    loanAmountMinimum: 'None',
    date: 'All',
  });

  const [leadsToShow, setLeadsToShow] = useState([]);
  const [loading, setLoading] = useState(false);
  const [expandedLeadId, setExpandedLeadId] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [response, setResponse] = useState(null);
  const [loadingMore, setLoadingMore] = useState(false);

  

  useEffect(() => {
    const fetchLeads = async () => {
      const authHeader = getAuthHeader();
      setLoading(true);
  
      try {
        const { availableLeads, availableLeadsCount } = await apiService.fetchAvailableLeads(
          authHeader,
          filters.search,
          filters.loanAmountMinimum,
          filters.state,
          filters.date
        );
  
        // Update the state only if the data has changed to avoid unnecessary renders
        setAvailableLeads((prev) => {
          if (JSON.stringify(prev) !== JSON.stringify(availableLeads)) {
            return availableLeads;
          }
          return prev;
        });
  
        setAvailableLeadsCount(availableLeadsCount);
  
        // Only update leadsToShow if necessary
        setLeadsToShow((prev) => {
          if (JSON.stringify(prev) !== JSON.stringify(availableLeads.slice(0, initialLoadCount))) {
            return availableLeads.slice(0, initialLoadCount);
          }
          return prev;
        });
      } catch (error) {
        console.error('Error fetching leads:', error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchLeads(); // Always fetch leads when dependencies change
  }, [filters, getAuthHeader, setAvailableLeads, setAvailableLeadsCount]);
    // Update leadsToShow when availableLeads change
  useEffect(() => {
    setLeadsToShow(availableLeads.slice(0, initialLoadCount));
  }, [availableLeads]);

  // Load more leads
  function loadMoreLeads() {
    if (leadsToShow.length >= availableLeadsCount) {
      setLoadingMore(false);
      return;
    }

    setTimeout(() => {
      setLeadsToShow((prevLeads) => [
        ...prevLeads,
        ...availableLeads.slice(prevLeads.length, prevLeads.length + initialLoadCount),
      ]);
      setLoadingMore(false);
    }, 1000); // Simulate network delay
  }

  // Handle scroll event
  const handleScroll = (e) => {
    if (loadingMore) return;

    const { scrollTop, clientHeight, scrollHeight } = e.target;

    // Calculate how far the user is from the bottom
    const distanceFromBottom = scrollHeight - scrollTop - clientHeight;

    // Adjust the threshold as needed (e.g., 100 pixels)
    if (distanceFromBottom <= 100) {
      setLoadingMore(true);
      loadMoreLeads();
    }
  };
  

  const handleExpandClick = (leadId) => {
    setExpandedLeadId((prevId) => (prevId === leadId ? null : leadId));
  };

  const handleBackToList = () => {
    setExpandedLeadId(null);
  };

  const handlePurchase = async (leadId) => {
    setLoading(true);
    setResponse(null);

    try {
      const authHeader = getAuthHeader();

      // Purchase the lead
      const { availableLeads, availableLeadsCount, message } = await apiService.handlePurchase(authHeader, leadId);
      const { purchasedLeads } = await apiService.fetchPurchasedLeads(authHeader);

      // Show the "Purchase successful!" message
      setResponse(message);

      // Delay collapse and refresh for 1 second to ensure the success message is visible
      setTimeout(async () => {
        // Update leads after purchase
        setAvailableLeads(availableLeads);
        setAvailableLeadsCount(availableLeadsCount);
        setLeadsToShow(availableLeads.slice(0, initialLoadCount));
        setPurchasedLeads(purchasedLeads);

        // Update user profile
        const userProfile = await apiService.fetchUserProfile(authHeader);
        setUser(userProfile);

        // Close expanded lead
        setExpandedLeadId(null);

        // Clear the response message
        setResponse(null);
      }, 2000); // Delay for 1 second to keep the message visible
    } catch (error) {
      console.error('Error purchasing lead:', error);
      setResponse('Error purchasing lead. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const leadsListAndFilters = (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
      <LeadsFiltersComponent
        filters={filters}
        handleSearchChange={(e) =>
          setFilters((prevFilters) => ({ ...prevFilters, search: e.target.value }))
        }
        handleStateChange={(state) =>
          setFilters((prevFilters) => ({ ...prevFilters, state }))
        }
        handleAmountChange={(amount) =>
          setFilters((prevFilters) => ({ ...prevFilters, loanAmountMinimum: amount }))
        }
        handleDateChange={(date) =>
          setFilters((prevFilters) => ({ ...prevFilters, date }))
        }
        leadsCount={availableLeadsCount}
        resetFilters={() =>
          setFilters({ search: '', state: 'All', loanAmountMinimum: 'None', date: 'All' })
        }
        drawerOpen={drawerOpen}
        setDrawerOpen={setDrawerOpen}
        isMobile={isMobile}
      />
      <LeadsListComponent
        leadsToShow={leadsToShow}
        onScroll={handleScroll}
        loadingMore={loadingMore}
        handleExpandClick={handleExpandClick}
      />
    </Box>
  );

  const expandedLeadComponent = expandedLeadId ? (
    <ExpandedLead
      lead={availableLeads.find((lead) => lead._id === expandedLeadId)}
      isMobile={isMobile}
      handleBackToList={handleBackToList}
      loading={loading}
      handlePurchase={handlePurchase}
      response={response}
    />
  ) : (<Dashboard/>

  );

  return (
    <AuthWrapper user={user}>
      <Box
        sx={{
          backgroundColor: '#F5F7FA',
          minHeight: '100vh',
          paddingBottom: 'env(safe-area-inset-bottom, 50px)',
        }}
      >
        {isMobile ? (
          <MobileView
            leadsListComponent={leadsListAndFilters}
            expandedLeadId={expandedLeadId}
            expandedLeadComponent={expandedLeadComponent}
          />
        ) : (
          <DesktopView
            leadsListComponent={leadsListAndFilters}
            expandedLeadComponent={expandedLeadComponent}
          />
        )}
      </Box>
    </AuthWrapper>
  );
}

export default LeadsList;
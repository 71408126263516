// Login.js
import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';
import { Box, Typography, Grid, useMediaQuery, TextField, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useAuth } from '../contexts/AuthContext';

function Login() {
  const [formValues, setFormValues] = useState({
    email: '',
    password: '',
  });
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { setUser } = useAuth()
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Detect if the screen is mobile size

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/login`,
        formValues
      );
      sessionStorage.setItem('user', JSON.stringify(formValues));
      console.log('login data', response.data);
      setUser(response.data);
      // setStatus(response.data.status)
      navigate('/leads');

      // if (response.data.status === 'pending') {
      //   // If the user status is pending, redirect to the sign-up fee page
      //   // navigate('/signup-fee');
      //   navigate('/leads');

      // } else {
      //   // Otherwise, redirect to the dashboard or another appropriate page
      //   navigate('/leads');
      // }
    } catch (err) {
      console.error('[Client] Login error:', err);
      setError('Invalid email or password');
    }
  };

  // Define your input fields
  const fields = [
    { name: 'email', label: 'Email', type: 'email' },
    { name: 'password', label: 'Password', type: 'password' },
  ];

  // Define your navigation buttons
  const navButtons = [
    { text: 'Sign Up', to: '/signup' },
    { text: 'Forgot Password', to: '/reset-password-request' },
  ];

  return (
    <Box sx={{ maxWidth: 450, mx: 'auto', mt: 4, p: 2, boxShadow: 2 }}>
      <Typography variant="h4" component="h1" gutterBottom>
        Login
      </Typography>
      {error && <Typography color="error">{error}</Typography>}
      <form onSubmit={handleSubmit}>
        {fields.map((field, index) => (
         <TextField
         key={index}
         label={field.label}
         type={field.type}
         value={formValues[field.name]}
         onChange={(e) =>
           setFormValues({ ...formValues, [field.name]: e.target.value })
         }
         required
         fullWidth
         margin="normal"
       />
        ))}
        <Button type="submit"
            variant="contained"
            color="primary"
            fullWidth>Login</Button>
        <Grid container spacing={2} sx={{ mt: 2 }}>
  {navButtons.map((button, index) => (
    <Grid item xs={6} key={index}>
      <Button
        component={Link}
        to={button.to}
        variant="outlined"
        fullWidth  // Ensures the buttons take up equal width
        sx={{
          fontSize: isMobile ? '0.75rem' : '0.9rem',
          height: '100%',  // Ensures both buttons have equal height
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        {button.text}
      </Button>
    </Grid>
  ))}
</Grid>
      </form>
    </Box>
  );
}

export default Login;
import React, { useState } from 'react';
import { Route, Routes, Navigate, BrowserRouter as Router } from 'react-router-dom';
import { Box, CssBaseline } from '@mui/material';
import { AuthProvider } from './contexts/AuthContext';
import { LayoutProvider } from './contexts/LayoutContext';
import LeadsList from './components/leadslist/LeadsList';
import Login from './components/Login';
import Settings from './components/Settings';
import SignUp from './components/SignUp';
import PasswordResetRequest from './components/PasswordResetRequest';
import ResetPassword from './components/ResetPassword';
import NavBar from './components/NavBar';
import BasicNavBar from './components/BasicNavBar';
import InitialDataLoader from './components/InitialDataLoader';
import { Helmet } from 'react-helmet';

function App() {
  const [user, setUser] = useState(null);

  return (
    <Router>
      <AuthProvider user={user} setUser={setUser}>
        <LayoutProvider>
          <Helmet>
            <title>The Lead Matrix</title>
          </Helmet>
          <CssBaseline />
          <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', backgroundColor: '#F5F7FA' }}>
            {user ? (
              <>
                <NavBar />
                <InitialDataLoader>
                  <Routes>
                    <Route path="/leads" element={<LeadsList />} />
                    <Route path="/settings" element={<Settings />} />
                    <Route path="*" element={<Navigate to="/leads" />} />
                  </Routes>
                </InitialDataLoader>
              </>
            ) : (
              <>
                <BasicNavBar />
                <Routes>
                  <Route path="/login" element={<Login />} />
                  <Route path="/signup" element={<SignUp />} />
                  <Route path="/reset-password-request" element={<PasswordResetRequest />} />
                  <Route path="/reset-password/:token" element={<ResetPassword />} />
                  <Route path="*" element={<Navigate to="/login" />} />
                </Routes>
              </>
            )}
          </Box>
        </LayoutProvider>
      </AuthProvider>
    </Router>
  );
}

export default App;
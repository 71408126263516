import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useAuth } from '../../contexts/AuthContext';
import AuthWrapper from '../AuthWrapper';
import LeadsChart from './LeadsChart';
import MyLeads from './MyLeads';

function Dashboard() {
  const { user, purchasedLeads } = useAuth();
  const [startDate, setStartDate] = useState(null);

  useEffect(() => {
    if (purchasedLeads.length > 0) {
      const firstPurchaseDate = new Date(
        Math.min(...purchasedLeads.map((lead) => new Date(lead.purchasedDate)))
      );
      firstPurchaseDate.setHours(firstPurchaseDate.getHours() + 10); // Convert UTC to AEST
      setStartDate(firstPurchaseDate.toISOString().split('T')[0]);
    }
  }, [purchasedLeads]);

  const aggregatedData = purchasedLeads.reduce((acc, lead) => {
    const date = new Date(lead.purchasedDate);
    date.setHours(date.getHours() + 10); // Convert UTC to AEST
    const isoDate = date.toISOString().split('T')[0];

    const options = { day: '2-digit', month: 'short', year: 'numeric' };
    const formattedDate = date.toLocaleDateString('en-AU', options);

    if (!acc[isoDate]) {
      acc[isoDate] = { date: isoDate, formattedDate, totalLeads: 0, totalSpent: 0 };
    }

    acc[isoDate].totalLeads += 1;
    acc[isoDate].totalSpent += lead.price;

    return acc;
  }, {});

  const chartData = Object.values(aggregatedData).sort(
    (a, b) => new Date(a.date) - new Date(b.date)
  );

  return (
    <AuthWrapper user={user}>
      {user && (
        <Box
          sx={{
            p: 1, // General padding for the page
            height: 'calc(100vh - 64px)', // Subtract NavBar height from viewport height
            display: 'flex',
            flexDirection: 'column',
            gap: 1, // Add spacing between components
          }}
        >
          {/* Title */}
          <Typography variant="h4" >
            Welcome back, {user.firstName}
          </Typography>

          {/* Chart and MyLeads Stacked */}
          <Box
            sx={{
              flex: 1, // Allow the entire section to grow and shrink
              display: 'flex',
              flexDirection: 'column', // Stack components vertically
              gap: 2, // Space between chart and leads
              overflow: 'hidden', // Prevent overflow of child components
            }}
          >
            {/* Leads Chart */}
            <Box
              sx={{
                flex: '1 1 auto', // Chart grows into remaining space
                minHeight: 0, // Prevent flexbox overflow issues
                backgroundColor: 'white', // Match Dashboard box styles
                borderRadius: '10px', // Rounded corners
                boxShadow: 1, // Box shadow for styling
                overflow: 'hidden', // Ensure content does not overflow
                p: 0, // Padding inside the chart box
              }}
            >
              <LeadsChart data={chartData} startDate={startDate} />
            </Box>

            {/* MyLeads */}
            <Box
              sx={{
                flexShrink: 1, // Allow the box to shrink if the content is small
                flexGrow: 0, // Prevent it from growing beyond its content size
                maxHeight: '50%', // Restrict MyLeads to no more than half the available height
                minHeight: 0, // Prevent flexbox overflow issues
                backgroundColor: 'white',
                borderRadius: '10px',
                boxShadow: 1,
                overflow: 'hidden', // Ensure content does not overflow
                p: 0, // Padding inside the MyLeads box
                mb:1
              }}
            >
              <MyLeads />
            </Box>
          </Box>
        </Box>
      )}
    </AuthWrapper>
  );
}

export default Dashboard;
import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import {
  Elements,
} from '@stripe/react-stripe-js';
import {
  Switch,
  FormControlLabel,
  Typography,
  Container,
  Paper,
  Box,
  Button,
  TextField,
  Grid,
  Alert,
  Modal,
  IconButton,
} from '@mui/material';
import { useAuth } from '../contexts/AuthContext'; // Import useAuth to access authHeader
import apiService from '../services/apiService'; // Import the apiService
import axios from 'axios';
import CloseIcon from '@mui/icons-material/Close'; // Import Close icon for modal
import DeleteIcon from '@mui/icons-material/Delete';
import AddCardForm from './AddCardForm';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'; // Import the outlined version
import { ArrowBack } from '@mui/icons-material'; // Import the back arrow icon
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const Settings = () => {
  const { storedCards, setStoredCards, getAuthHeader, user } = useAuth();
  const authHeader = getAuthHeader();
  const navigate = useNavigate(); // Initialize navigation hook

  const [emailNotifications, setEmailNotifications] = useState(false);
  const [loading, setLoading] = useState(false);

  const [notificationsResponse, setNotificationsResponse] = useState(null);
  const [notificationsError, setNotificationsError] = useState(null);
  
  const [paymentResponse, setPaymentResponse] = useState(null);
  const [paymentError, setPaymentError] = useState(null);
  
  const [webhookResponse, setWebhookResponse] = useState(null);
  const [webhookError, setWebhookError] = useState(null);

  // Webhook state
  const [url, setUrl] = useState('');
  const [headers, setHeaders] = useState('');
  const [openWebhookModal, setOpenWebhookModal] = useState(false);

// Payment information
  const [openAddCardModal, setOpenAddCardModal] = useState(false);

  const timeout = 1000

  // Fetch the user's current notification setting and webhook config on mount
  useEffect(() => {
    const fetchSettings = async () => {
      try {
        
        // Fetch email notifications setting
        const setting = await apiService.fetchNotificationSettings(authHeader);
        setEmailNotifications(setting);

   
          const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/webhook/configure`, {
            headers: {
              Authorization: authHeader,
            },
          });
          const config = res.data;
         

        if (config.url) setUrl(config.url);
        if (config.headers) setHeaders(config.headers);
      } catch (err) {
        console.error("Error fetching settings:", err);
        setTimeout(() =>  setWebhookError("Failed to load settings."), timeout);
      }
    };

    if (user) {
      fetchSettings();
    }
  }, [user, authHeader]);

  const handleOpenAddCardModal = () => setOpenAddCardModal(true);
  const handleCloseAddCardModal = () => setOpenAddCardModal(false);
console.log("stored cards:", storedCards)

  const handleRemoveCard = async (cardId) => {
    setLoading(true);
    try {
      // Call the API to remove the card and get the updated list of stored cards
      const updatedStoredCards = await apiService.removeStoredCard(cardId, authHeader);
  
      // Update the stored cards state with the new list
      setStoredCards(updatedStoredCards);
  
      // Show success message
      setPaymentResponse('Card removed successfully!');
      setTimeout(() => setPaymentResponse(null), timeout); // Clear the message after the timeout
    } catch (err) {
      console.error('Error removing card:', err);
  
      // Show error message
      setPaymentError('Failed to remove card.');
      setTimeout(() => setPaymentError(null), timeout); // Clear the message after the timeout
    } finally {
      setLoading(false); // Stop the loading spinner
    }
  };


  // Handle email notifications toggle
  const handleToggleChange = async () => {
    setLoading(true);
    setNotificationsError(null);
  
    try {
      const newSetting = !emailNotifications;
      setEmailNotifications(newSetting);
      await apiService.updateNotificationSettings(authHeader, newSetting);
      setNotificationsResponse('Email notification preferences saved successfully!');
      setTimeout(() => setNotificationsResponse(null), timeout); // Use timeout variable
    } catch (err) {
      console.error('Error updating notification setting:', err);
      setNotificationsError('Failed to update settings.');
      setTimeout(() => setNotificationsError(null), timeout); // Use timeout variable
    } finally {
      setLoading(false);
    }
  };

  const handleSendTestRequest = async () => {
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/api/webhook/proxy`,
        { url, headers },
        {
          headers: {
            Authorization: getAuthHeader(),
          },
        }
      );
      setWebhookResponse('Test request sent successfully!');
      setTimeout(() => setWebhookResponse(null), timeout); // Use timeout variable
    } catch (err) {
      setWebhookError(`Test request failed: ${err.message}`);
      setTimeout(() => setWebhookError(null), timeout); // Use timeout variable
    }
  };
  
  // Handle saving webhook configuration
const handleSaveConfiguration = async () => {
  const configuration = { url, headers };
  try {
    await axios.post(`${process.env.REACT_APP_API_URL}/api/webhook/configure`, configuration, {
      headers: {
        Authorization: getAuthHeader(),
      },
    });

    // Save configuration to sessionStorage
    sessionStorage.setItem('webhookConfig', JSON.stringify(configuration));

    // Set success response and clear it after the timeout
    setWebhookResponse('Webhook configuration saved successfully!');
    setTimeout(() => setWebhookResponse(null), timeout);
  } catch (error) {
    // Set error response and clear it after the timeout
    setWebhookError(`Unable to save webhook configuration: ${error.message}`);
    setTimeout(() => setWebhookError(null), timeout);
  }
};

  // Modal open/close handlers for JSON structure info
  const handleOpenWebhookModal = () => setOpenWebhookModal(true);
  const handleCloseWebhookModal = () => setOpenWebhookModal(false);

  const handleBackToLeads = () => {
    navigate('/leads'); // Navigate back to the Leads page
  };


  return (
<Container
  component={Paper}
  style={{
    padding: '1rem',
    maxWidth: 600,
    maxHeight: '90vh', // Set a maximum height for the container
    overflowY: 'auto', // Enable vertical scrolling
   
  }}
  elevation={1}
>
   
<Box display="flex" alignItems="center" mb={2}>
        {/* Back Button */}
        <IconButton
          sx={{
            p: 1,
            pl: 0,
            '&:hover': {
              backgroundColor: 'transparent', // Removes the hover background
            },
          }}
          onClick={handleBackToLeads}
        >
          <ArrowBack />
        </IconButton>
        <Typography variant="h5" >
          Settings
        </Typography>
      </Box>
      {notificationsResponse && <Alert severity="success">{notificationsResponse}</Alert>}
      {notificationsError && <Alert severity="error">{notificationsError}</Alert>}

      <FormControlLabel
        control={
          <Switch
            checked={emailNotifications}
            onChange={handleToggleChange}
            disabled={loading}
          />
        }
        label="New Lead Email Notifications"
      />
  {/* Payment Information Section */}
  <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
        Payment Settings
      </Typography>
      {paymentError && <Alert severity="error">{paymentError}</Alert>}
      {paymentResponse && <Alert severity="success">{paymentResponse}</Alert>}

      <Paper sx={{ p: 1, mb: 2 , }} elevation={0}>
      {storedCards.length === 0 ? (
  <Typography>No stored cards.</Typography>
) : (
  storedCards.map((card) => {
    const cardInfo = card.card || {}; // Fallback to an empty object
    return (
      <Box
        key={card.id}
        display="flex"
        // justifyContent="space-between"
        alignItems="center"
        gap="20px"
        sx={{ mb: 2 }}
      >
        <Typography>{`**** **** **** ${cardInfo.last4 || '????'} - ${String(cardInfo.exp_month || '--').padStart(2, '0')}/${String(cardInfo.exp_year || '--').slice(-2)}`}</Typography>
        <IconButton onClick={() => handleRemoveCard(card.id)} disabled={loading}>
          <DeleteIcon />
        </IconButton>
      </Box>
    );
  })
)}
        <Button variant="contained" onClick={handleOpenAddCardModal} sx={{ mt: 2, fontSize: { xs: '0.75rem', sm: '0.875rem', md: '0.875rem' }}}>
          Add New Card
        </Button>
      </Paper>

      {/* Add Card Modal */}
      <Modal open={openAddCardModal} onClose={handleCloseAddCardModal}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 2,
            width: '80%',
            maxWidth: '400px',
            borderRadius: '8px',
          }}
        >
                    <Elements stripe={stripePromise}>

          <AddCardForm
            user={user}
            authHeader={authHeader}
            onSuccess={(newCard) => {
              setStoredCards((prev) => [...prev, newCard]);
              handleCloseAddCardModal();
            }}
            onClose={handleCloseAddCardModal}
          />
                    </Elements>

        </Box>
      </Modal>
      <Box sx={{ mt: 4 }}>
        <Box display="flex" alignItems="center" sx={{ mb: 1 }}>
          <Typography variant="h5" gutterBottom  sx={{ mb: 0 }}>
            Webhook Configuration
          </Typography>
          <IconButton onClick={handleOpenWebhookModal} sx={{ ml: 2, p: 0 }}>
          <InfoOutlinedIcon /> {/* Use the outlined variant */}
          </IconButton>
        </Box>
        <Typography variant="body2" gutterBottom sx={{fontSize: { xs: '0.6rem', sm: '0.75rem', md: '0.75rem' } }}>
          Purchased leads will be POSTed to the URL you specify. Ensure the destination is set up to receive a JSON body. The Content-Type header is already application/json.
        </Typography>
        <Paper sx={{ p: 2, pl: 0, mb: 0 }} elevation={0}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="URL"
                value={url}
                onChange={(e) => setUrl(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Headers (JSON)"
                value={headers}
                onChange={(e) => setHeaders(e.target.value)}
                fullWidth
                multiline
                rows={4}
                placeholder='{"Content-Type": "application/json"}'
              />
            </Grid>
            <Grid item xs={12} sx={{ textAlign: 'left' }}>
              <Button variant="contained" onClick={handleSendTestRequest} sx={{ mr: 2 }}>
                Send Test Request
              </Button>
              <Button variant="contained" onClick={handleSaveConfiguration}>
                Save
              </Button>
            </Grid>
          </Grid>
        </Paper>
        {webhookResponse && (
            <Alert severity="success" sx={{ mt: 2 }}>
              {webhookResponse}
            </Alert>
          )}
          {webhookError && (
            <Alert severity="error" sx={{ mt: 2 }}>
              {webhookError}
            </Alert>
          )}
       
      </Box>

      {/* Modal for displaying JSON structure */}
      <Modal open={openWebhookModal} onClose={handleCloseWebhookModal}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            width: '80%',
            maxWidth: '600px',
            borderRadius: '8px',
          }}
        >
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h6">JSON Structure</Typography>
            <IconButton onClick={handleCloseWebhookModal}>
              <CloseIcon />
            </IconButton>
          </Box>
          <pre>{JSON.stringify({
            name: "Test Name",
            email: "test@example.com",
            phone: "0411111111",
            price: 100,
            date: new Date(),
            details: {
              monthlyIncome: 5000,
              loanAmount: 200000,
              houseValue: 300000,
              credit: "Good",
              employment: "Employed",
              address: "123 Test St",
              state: "NSW",
              postcode: "2000",
              currentRate: "5%",
              loanType: "Fixed"
            }
          }, null, 2)}</pre>
        </Box>
      </Modal>
    </Container>
  );
};







export default Settings;
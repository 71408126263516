import { createContext, useContext, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
// import apiService from '../services/apiService';

const AuthContext = createContext();

// Custom hook to use the AuthContext
export const useAuth = () => {
  return useContext(AuthContext);
};

// Provider component
export const AuthProvider = ({ user, setUser, children }) => {
  // Existing state
  // const [adminUsers, setAdminUsers] = useState([]);
  const [purchasedLeads, setPurchasedLeads] = useState([]);
  // const [settledCount, setSettledCount] = useState(0);
  // const [conversionRate, setConversionRate] = useState(0);
  const [availableLeads, setAvailableLeads] = useState([]);
  const [availableLeadsCount, setAvailableLeadsCount] = useState(0);
  // const [status, setStatus] = useState('pending'); // Track sign-up fee status: 'pending' or 'active'

  // New state for stored cards
  const [storedCards, setStoredCards] = useState([]);
  // const [fetchingCards, setFetchingCards] = useState(false); // Loading state for stored cards
  const [cardError, setCardError] = useState(null); // Error state for stored cards

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));

  // Function to get basic auth header
  const getAuthHeader = () => {
    const storedUser = JSON.parse(sessionStorage.getItem('user'));
    if (!storedUser || !storedUser.email || !storedUser.password) {
      throw new Error('User credentials not found in session storage');
    }
    const credentials = `${storedUser.email}:${storedUser.password}`;
    return `Basic ${btoa(credentials)}`;
  };

  



  return (
    <AuthContext.Provider
      value={{
        // Existing context values
        isMobile,
        isMdUp,
        user,
        setUser,
        // adminUsers,
        // setAdminUsers,
        getAuthHeader,
        purchasedLeads,
        setPurchasedLeads,
        // settledCount,
        // setSettledCount,
        // conversionRate,
        // setConversionRate,
        availableLeads,
        setAvailableLeads,
        availableLeadsCount,
        setAvailableLeadsCount,
        // status,
        // setStatus,

        // New stored cards values
        storedCards,
        setStoredCards,
        cardError,
        setCardError,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import apiService from '../services/apiService';
import { useAuth } from '../contexts/AuthContext';
import { Box, CircularProgress } from '@mui/material';

function InitialDataLoader({ children }) {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const fetchCalled = useRef(false);
  const { 
    // setUser,
    getAuthHeader, 
    // setAdminUsers,
    setAvailableLeads, 
    setAvailableLeadsCount,
    setPurchasedLeads, 
    // setSettledCount, 
    // setConversionRate,
    setStoredCards,
    setCardError,
  } = useAuth(); // Get values and setters from the context

  const fetchData = useCallback(async () => {
    const authHeader = getAuthHeader();

    if (fetchCalled.current) {
      console.log("InitialDataLoader: Fetching already completed or in progress");
      // setLoading(false);

      return;
    }
    fetchCalled.current = true;

    try {
      console.log("InitialDataLoader: Starting data fetch");
      // const userProfile = await apiService.fetchUserProfile(authHeader);
      // setUser(userProfile);

      // Fetch available leads
      const { availableLeads, availableLeadsCount } = await apiService.fetchAvailableLeads(authHeader);
      setAvailableLeads(availableLeads)
      setAvailableLeadsCount(availableLeadsCount)


      // Fetch purchased leads and update context
      const { purchasedLeads,
        //  settledCount, conversionRate 
        } = await apiService.fetchPurchasedLeads(authHeader);
      setPurchasedLeads(purchasedLeads); // Store the purchased leads in context
      // setSettledCount(settledCount);
      // setConversionRate(conversionRate);

      // // Fetch admin users if the user is an admin
      // if (userProfile.role === 'admin') {
      //  const adminUsers =  await apiService.fetchAdminUsers(authHeader);
      //  setAdminUsers(adminUsers)
      // }

      const fetchStoredCards = async () => {
        try {
          const cards = await apiService.fetchStoredCards(authHeader);
          setStoredCards(cards || []);
        } catch (err) {
          console.error('Error fetching stored cards:', err);
          setCardError('Failed to load stored cards.');
        }
      };
      fetchStoredCards();
    
    


      console.log("InitialDataLoader: Data fetch completed");
      setLoading(false);
    } catch (error) {
      console.error('Error fetching initial data:', error);
      if (error.response && error.response.status === 401) {
        navigate('/login');
      }
    }
  }, [
    // setUser, 
    navigate, getAuthHeader, setPurchasedLeads, 
    // setSettledCount, setConversionRate, 
    setAvailableLeads, setAvailableLeadsCount, setStoredCards, setCardError]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',  // Full viewport height for centering
          minHeight: '100vh',
          padding: '16px',
        }}
      >
        <CircularProgress size={40} />
      </Box>
    );
  }

  return <>{children}</>;
}

export default InitialDataLoader;
import React, { useState, useCallback } from 'react';
import {
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Pagination,
  MenuItem,
  Select,
  FormControl,
  Button,
} from '@mui/material';
import { ArrowDropUp, ArrowDropDown } from '@mui/icons-material';
import { useAuth } from '../../contexts/AuthContext';
import apiService from '../../services/apiService';
import { paginateLeads, formatCurrency } from '../../utils/Utils';

function MyLeads() {
  const { user, purchasedLeads, getAuthHeader } = useAuth();
  const authHeader = getAuthHeader?.();
  const [currentPage, setCurrentPage] = useState(1);
  const [leadsPerPage, setLeadsPerPage] = useState(10);
  const [showDetails, setShowDetails] = useState(false);
  const [sortConfig, setSortConfig] = useState({ key: 'purchasedDate', direction: 'desc' });

  const basicColumns = [
    { id: 'purchasedDate', label: 'Purchase Date', format: (value) => new Date(value).toLocaleDateString() },
    { id: 'name', label: 'Name', minWidth: 150 },
    { id: 'email', label: 'Email' },
    { id: 'phone', label: 'Phone', format: (value) => `${value.slice(0, 4)} ${value.slice(4, 7)} ${value.slice(7)}` },
  ];

  const detailsColumns = [
    { id: 'details.monthlyIncome', label: 'Monthly Income', format: (value) => formatCurrency(value) },
    { id: 'details.loanAmount', label: 'Loan Amount', format: (value) => formatCurrency(value) },
    { id: 'details.houseValue', label: 'House Value', format: (value) => formatCurrency(value) },
    { id: 'details.credit', label: 'Credit' },
    { id: 'details.employment', label: 'Employment' },
  ];

  const paginatedLeads = paginateLeads(purchasedLeads, currentPage, leadsPerPage);

  const trackShowDetails = useCallback((isShowingDetails) => {
    const subType = isShowingDetails ? 'show' : 'hide';
    apiService.trackAction('toggleDetails', authHeader, { subType });
  }, [authHeader]);

  const handleSort = (key) => {
    const direction = sortConfig.key === key && sortConfig.direction === 'asc' ? 'desc' : 'asc';
    setSortConfig({ key, direction });
  };

  const sortedLeads = [...paginatedLeads].sort((a, b) => {
    if (sortConfig.key === 'purchasedDate') {
      return sortConfig.direction === 'asc'
        ? new Date(a.purchasedDate) - new Date(b.purchasedDate)
        : new Date(b.purchasedDate) - new Date(a.purchasedDate);
    }
    return 0;
  });

  return (
    user && (
      <Paper
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          overflow: 'hidden',
          p: 0,
        }}
        elevation={1}
      >
        {/* Header */}
        <Typography
          variant="h6"
          sx={{ p: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderBottom: '1px solid #ccc' }}
        >
          Purchased Leads ({purchasedLeads.length} Total)
          <Button
            variant="contained"
            onClick={() => {
              const newState = !showDetails;
              setShowDetails(newState);
              trackShowDetails(newState);
            }}
            sx={{ fontSize: '0.875rem' }}
          >
            {showDetails ? 'Hide Details' : 'Show Details'}
          </Button>
        </Typography>

        {/* Table */}
        <TableContainer sx={{ flex: 1, overflowY: 'auto' }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {[...basicColumns, ...(showDetails ? detailsColumns : [])].map((column) => (
                  <TableCell
                    key={column.id}
                    sx={{ p: 1, textAlign: 'center', cursor: column.id === 'purchasedDate' ? 'pointer' : 'default' }}
                    onClick={() => column.id === 'purchasedDate' && handleSort(column.id)}
                  >
                    {column.label}
                    {sortConfig.key === column.id && (
                      sortConfig.direction === 'asc' ? <ArrowDropUp /> : <ArrowDropDown />
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedLeads.map((lead) => (
                <TableRow key={lead._id}>
                  {[...basicColumns, ...(showDetails ? detailsColumns : [])].map((column) => (
                    <TableCell key={column.id} sx={{ p: 1, textAlign: 'center' }}>
                      {column.format
                        ? column.format(
                            column.id.includes('.')
                              ? column.id.split('.').reduce((o, key) => o[key], lead)
                              : lead[column.id]
                          )
                        : column.id.includes('.')
                        ? column.id.split('.').reduce((o, key) => o[key], lead)
                        : lead[column.id]}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        {/* Footer */}
        <Typography
          component="div"
          sx={{ p: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderTop: '1px solid #ccc' }}
        >
          <Pagination count={Math.ceil(purchasedLeads.length / leadsPerPage)} page={currentPage} onChange={(_, value) => setCurrentPage(value)} />
          <FormControl>
            <Select
              value={leadsPerPage}
              onChange={(e) => setLeadsPerPage(e.target.value)}
              sx={{ height: '30px' }}
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={25}>25</MenuItem>
              <MenuItem value={50}>50</MenuItem>
            </Select>
          </FormControl>
        </Typography>
      </Paper>
    )
  );
}

export default MyLeads;
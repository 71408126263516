import React, { useEffect, useRef, useMemo } from 'react';
import { Chart, registerables } from 'chart.js';
import 'chartjs-adapter-date-fns';
import { Box, Paper } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

Chart.register(...registerables);

const LeadsChart = ({ data, startDate }) => {
  const chartRef = useRef(null); // Reference to the Chart instance
  const canvasRef = useRef(null); // Reference to the canvas element
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const dataset = useMemo(() => {
    return [
      {
        label: 'Number of Leads',
        data: data.map((item) => item.totalLeads || 0), // Use fallback for missing data
        backgroundColor: 'rgba(33, 150, 243, 0.8)',
        borderColor: 'rgba(33, 150, 243, 1)',
        borderWidth: 1,
        borderRadius: 5,
        barThickness: isMobile ? 6 : 8,
        maxBarThickness: 10,
        yAxisID: 'y',
      },
    ];
  }, [data, isMobile]);

  const options = useMemo(() => {
    return {
      responsive: true,
      maintainAspectRatio: false, // Ensure the chart adjusts to container
      layout: {
        padding: {
          bottom: 0,
          top: 0,
        },
      },
      scales: {
        x: {
          type: 'time',
          time: { unit: 'day' },
          min: startDate,
          max: data.length
            ? new Date(Math.max(...data.map((d) => new Date(d.date))))
            : undefined,
          grid: {
            display: false, // Hide vertical grid lines
          },
          title: {
            display: false, // Disable the X-axis title
          },
          ticks: {
            maxRotation: 30,
            autoSkip: true,
          },
        },
        y: {
          beginAtZero: true,
          title: {
            display: true,
            text: 'Number of Leads', // Fixed label
            font: {
              size: 14,
              weight: 'bold',
            },
            color: '#333',
          },
          grid: {
            display: true,
          },
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
            enabled: true,
            callbacks: {
              label: function (context) {
                return `Leads: ${context.raw}`;
              },
              title: function (context) {
                const index = context[0].dataIndex; // Get the index of the hovered data point
                const rawDate = data[index]?.date; // Access the raw date from the data array
                // console.log('Raw Date:', rawDate);
                const date = new Date(rawDate); // Parse the original date
                return date.toLocaleDateString('en-AU', {
                  day: '2-digit',
                  month: 'short',
                  year: 'numeric',
                });
              },
            },
          },
      },
    };
  }, [data, startDate]);

  useEffect(() => {
    const ctx = canvasRef.current?.getContext('2d');
    if (!ctx) {
      console.error('Canvas context is not available');
      return;
    }

    if (chartRef.current) {
      chartRef.current.data.labels = data.map((item) => item.date);
      chartRef.current.data.datasets = dataset;
      chartRef.current.options = options;
      chartRef.current.update();
    } else {
      chartRef.current = new Chart(ctx, {
        type: 'bar',
        data: {
          labels: data.map((item) => item.date),
          datasets: dataset,
        },
        options,
      });
    }

    return () => {
      chartRef.current?.destroy();
      chartRef.current = null;
    };
  }, [dataset, options, data]);

  return (
    <Paper sx={{ p: 2, height: '100%' }}>
      <Box sx={{ height: '100%' }}>
        <canvas ref={canvasRef} />
      </Box>
    </Paper>
  );
};

export default LeadsChart;
import React from 'react';
import { Box } from '@mui/material';

const DesktopView = ({ leadsListComponent, expandedLeadComponent }) => (
  <Box
    sx={{
      display: 'flex',
      width: '100%', // Ensure full-width usage
      height: 'calc(100vh - 52px)', // Full viewport height minus the header
      overflow: 'hidden', // Prevent content overflow
    }}
  >
    {/* Leads List */}
    <Box
      sx={{
        width: '35%', // Default width allocation
        maxWidth: '450px', // Prevents it from exceeding a specific size
        display: 'flex',
        flexDirection: 'column',
        p: 0.5,
        flexShrink: 1, // Allow shrinking when the screen size decreases
        minWidth: 0, // Required to enable shrinking
        overflow: 'hidden', // Prevent overflow of its content
      }}
    >
      {leadsListComponent}
    </Box>

    {/* Expanded Lead or Default View */}
    <Box
      sx={{
        flex: 1, // Takes up remaining space
        display: 'flex',
        flexDirection: 'column',
        p: 1,
        minWidth: 0, // Allow shrinking horizontally
        overflow: 'hidden', // Prevent overflow of its content
      }}
    >
      {expandedLeadComponent}
    </Box>
  </Box>
);

export default DesktopView;
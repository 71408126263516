import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Typography,
  Alert,
  CircularProgress,
  TextField
} from '@mui/material';

function PasswordResetRequest() {
  const [formValues, setFormValues] = useState({
    email: '',
  });
  const [response, setResponse] = useState(''); // Added message state
  const [error, setError] = useState(''); // For success or error
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setResponse('');
    setError('');

    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/api/reset-password/request-reset`,
        { email: formValues.email },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      setResponse('Password reset email sent.');
    } catch (err) {
      setError('Error sending password reset email.');
    } finally {
      setLoading(false);
    }
  };

  const handleBackToLogin = () => {
    navigate('/login');
  };

  // Define your input fields
  const fields = [{ name: 'email', label: 'Email', type: 'email' }];

  return (
    <Box
    sx={{
      maxWidth: 500,
      mx: 'auto',
      mt: 1,
      p: 1,
      boxShadow: 'none', // Ensures no shadow (equivalent to no elevation)
      overflowY: 'auto', // Enables vertical scrolling
      maxHeight: '90vh', // Restricts the height to the viewport
    }}
    >   
        <Typography variant="h4" gutterBottom sx={{ fontSize: { xs: '1.5rem', sm: '1.75rem', md: '1.75rem' }, // Responsive text font size
}}>
          Request Password Reset
        </Typography>
        <form onSubmit={handleSubmit}>
          {fields.map((field, index) => (
            <TextField
              key={index}
              label={field.label}
              type={field.type}
              value={formValues[field.name]}
              fullWidth
              onChange={(e) =>
                setFormValues({
                  ...formValues,
                  [field.name]: e.target.value,
                })
              }
            />
          ))}
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            sx={{ mt: 2 ,  fontSize: { xs: '0.75rem', sm: '0.875rem', md: '0.875rem'}, // Responsive text font size
          }}
            disabled={loading}
          >
            {loading ? (
              <CircularProgress size={24} sx={{ color: 'white' }} />
            ) : (
              'Send Reset Email'
            )}
          </Button>
          <Button
            type="button"
            variant="outlined"
            onClick={handleBackToLogin}
            fullWidth
            sx={{ mt: 2 ,  fontSize: { xs: '0.75rem', sm: '0.875rem', md: '0.875rem' }, // Responsive text font size
          }}
          >
            Back to Login
          </Button>
        </form>
        {response && (
            <Alert severity="success" sx={{ mt: 2 }}>
              {response}
            </Alert>
          )}
          {error && (
            <Alert severity="error" sx={{ mt: 2 }}>
              {error}
            </Alert>
          )}
    </Box>
  );
}

export default PasswordResetRequest;
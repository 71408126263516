import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { 
  Box, 
  Typography, 
  CircularProgress, 
  IconButton, 
  Button,
  TextField,
  InputAdornment,
  Alert
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useAuth } from '../contexts/AuthContext';
import { useLayout } from '../contexts/LayoutContext';

function SignUp() {
  const [formValues, setFormValues] = useState({
    email: '',
    password: '',
    confirmPassword: '',
    firstName: '',
    lastName: '',
    company: '',
  });
  const [error, setError] = useState('');
  const [verificationSent, setVerificationSent] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState(['', '', '', '']);
  const [verifying, setVerifying] = useState(false);
  const [passwordVisibility, setPasswordVisibility] = useState({
    password: false,
    confirmPassword: false,
  });
  const { setUser } = useAuth();
  const { isMobile } = useLayout()

  const navigate = useNavigate();

  const togglePasswordVisibility = (field) => {
    setPasswordVisibility((prev) => ({
      ...prev,
      [field]: !prev[field],
    }));
  };

  const handleInputChange = (field, value) => {
    setFormValues((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleSubmit = async () => {
    setError('');
    if (formValues.password !== formValues.confirmPassword) {
      setError('Passwords do not match.');
      return;
    }

    setLoading(true);
    const { confirmPassword, ...dataToSend } = formValues;

    // Store email and password for later use
    sessionStorage.setItem('user', JSON.stringify({ email: dataToSend.email, password: dataToSend.password }));

    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/api/signup`, dataToSend);
      setVerificationSent(true);
    } catch (err) {
      console.error('[Client] Error during sign-up:', err);
      setError(err.response?.data || 'Error creating account. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleVerifyCode = async () => {
    setVerifying(true);
    setError('');
    try {
      const verificationCode = code.join('');
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/signup/verify`, {
        email: formValues.email,
        code: verificationCode,
      });

      // Show success message for 2 seconds before redirecting
      setSuccessMessage('Welcome to the Lead Matrix.');
      setTimeout(() => {
        setUser(response.data.user);
      }, 2000);
    } catch (err) {
      console.error('Error verifying code:', err);
      setError(err.response?.data || 'Invalid or expired verification code.');
    } finally {
      setVerifying(false);
    }
  };

  const handleResendCode = async () => {
    setLoading(true);
    setError('');
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/api/signup`, {
        email: formValues.email,
        password: formValues.password,
        firstName: formValues.firstName,
        lastName: formValues.lastName,
        company: formValues.company,
      });
      setSuccessMessage('Verification code resent. Please check your email.');
      setTimeout(() => setSuccessMessage(''), 5000);
    } catch (err) {
      console.error('Error resending verification code:', err);
      setError(err.response?.data || 'Error resending verification code. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleCodeChange = (index, value) => {
    if (!/^\d*$/.test(value)) return;
    const newCode = [...code];
    newCode[index] = value;
    setCode(newCode);

    if (value && index < 3) {
      document.getElementById(`code-${index + 1}`).focus();
    }
  };

  const formFields = [
    { name: 'firstName', label: 'First Name', type: 'text' },
    { name: 'lastName', label: 'Last Name', type: 'text' },
    { name: 'company', label: 'Company', type: 'text' },
    { name: 'email', label: 'Email', type: 'email' },
    {
      name: 'password',
      label: 'Password',
      type: passwordVisibility.password ? 'text' : 'password',
      toggleVisibility: () => togglePasswordVisibility('password'),
      showVisibilityIcon: true,
    },
    {
      name: 'confirmPassword',
      label: 'Confirm Password',
      type: passwordVisibility.confirmPassword ? 'text' : 'password',
      toggleVisibility: () => togglePasswordVisibility('confirmPassword'),
      showVisibilityIcon: true,
    },
  ];
  const handleBackToLogin = () => {
    navigate('/login');
  };
  return (
    <Box
      sx={{
        maxWidth: isMobile ? 400 : 500,
        mx: 'auto',
        mt: 1,
        p: 1,
        boxShadow: 'none',
        overflowY: 'auto',
        maxHeight: '90vh',
        display: 'flex',
        flexDirection: 'column', // Stack components vertically
        justifyContent: 'space-between', // Space between title and messages
        height: '100%', // Fill the parent container
      }}
    >
      <Box>
        {/* Title */}
        <Typography variant="h4" component="h1" gutterBottom>
          Sign Up
        </Typography>
  
        {successMessage ? (
          // Display only success message
          <Alert
            severity="success"
            sx={{ fontSize: '1.2rem', fontWeight: 'bold', mt: 2 }}
          >
            {successMessage}
          </Alert>
        ) : (
          // Show the form or verification code input if verification is not successful
          <>
            {!verificationSent ? (
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit();
                }}
              >
                {formFields.map(({ name, label, type, toggleVisibility, showVisibilityIcon }) => (
                  <TextField
                    key={name}
                    label={label}
                    type={type}
                    value={formValues[name]}
                    onChange={(e) => handleInputChange(name, e.target.value)}
                    fullWidth
                    margin="normal"
                    required
                    InputProps={
                      showVisibilityIcon
                        ? {
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton onClick={toggleVisibility} edge="end">
                                  {passwordVisibility[name] ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }
                        : null
                    }
                  />
                ))}
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={loading}
                  fullWidth
                  startIcon={loading ? <CircularProgress size={20} /> : null}
                >
                  {loading ? '' : 'Sign Up'}
                </Button>
                <Button
            type="button"
            variant="outlined"
            onClick={handleBackToLogin}
            fullWidth
            sx={{ mt: 2 ,  fontSize: { xs: '0.75rem', sm: '0.875rem', md: '0.875rem' }, // Responsive text font size
          }}
          >
            Back to Login
          </Button>

              </form>
            ) : (
              <Box>
               <Typography>
  A 4-digit verification code has been sent to {formValues.email}
  <br />
  Please enter it below:
</Typography>
                <Box sx={{ display: 'flex', justifyContent: 'center', gap: 1, mt: 2 }}>
                  {code.map((digit, index) => (
                    <TextField
                      key={index}
                      id={`code-${index}`}
                      inputProps={{ maxLength: 1, style: { textAlign: 'center' } }}
                      value={digit}
                      onChange={(e) => handleCodeChange(index, e.target.value)}
                      sx={{ width: 50 }}
                    />
                  ))}
                </Box>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={handleVerifyCode}
                  disabled={verifying}
                  startIcon={verifying ? <CircularProgress size={20} /> : null}
                  sx={{ mt: 2 }}
                >
                  {verifying ? 'Verifying...' : 'Verify Code'}
                </Button>
                <Button
                  variant="outlined"
                  fullWidth
                  onClick={handleResendCode}
                  disabled={loading}
                  sx={{ mt: 1 }}
                >
                  Resend Verification Code
                </Button>
              </Box>
            )}
          </>
        )}
      </Box>
  
      {/* Error Message at the Bottom */}
      {!successMessage && error && (
        <Box sx={{ mt: 'auto' }}>
          <Alert severity="error" sx={{ fontSize: '1rem', fontWeight: 'bold' }}>
            {error}
          </Alert>
        </Box>
      )}
    </Box>
  );
};
export default SignUp;
import React, { useState } from 'react'; // Import React and useState
import { Box, Typography, Button, CircularProgress, Alert } from '@mui/material'; // Import Material-UI components
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js'; // Import Stripe components
import apiService from '../services/apiService'; // Import apiService for API calls
import { styled } from '@mui/system';

const cardElementOptions = {
  style: {
    base: {
      fontSize: '16px',
      color: '#495057',
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      '::placeholder': {
        color: '#868e96',
        fontSize: '14px', // Adjust placeholder font size
        '@media (max-width:600px)': {
          fontSize: '10px', // Smaller placeholder font size for mobile
        },
      },
    },
    invalid: {
      color: '#fa755a',
    },
  },
  hidePostalCode: true, // Hides the postal code field
};

const CardElementContainer = styled('div')({
  padding: '15px 10px',
  borderRadius: '4px',
  border: '1px solid #ced4da',
  marginTop: '8px',
  backgroundColor: '#ffffff',
});

const AddCardForm = ({ user, authHeader, onSuccess, onClose }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  const handleAddCard = async (event) => {
    event.preventDefault();
    setLoading(true);
    setError(null);
    setSuccessMessage(null);

    try {
      const cardElement = elements.getElement(CardElement);
      if (!cardElement) {
        setError('Card details not found.');
        setLoading(false);
        return;
      }

      const paymentMethodResponse = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
      });

      if (paymentMethodResponse.error) {
        setError(paymentMethodResponse.error.message);
        setLoading(false);
        return;
      }

      const response = await apiService.storeNewCard(paymentMethodResponse.paymentMethod.id, authHeader);

      // Show success message for 1 second
      setSuccessMessage('Card added successfully!');
      setTimeout(() => {
        setSuccessMessage(null);
        console.log("api response" ,response)
        onSuccess(response.card); // Trigger the success callback
      }, 1000);
    } catch (err) {
      console.error('Error adding card:', err);
      setError('Failed to add card.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Add New Card
      </Typography>
      {error && <Alert severity="error">{error}</Alert>}
      {successMessage && <Alert severity="success">{successMessage}</Alert>}
      <form onSubmit={handleAddCard}>
        <CardElementContainer>
          <CardElement options={cardElementOptions} />
        </CardElementContainer>
        <Box display="flex" justifyContent="center" gap={2} sx={{ mt: 2 }}>
          <Button type="submit" variant="contained" disabled={loading}>
            {loading ? <CircularProgress size={24} /> : 'Save Card'}
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default AddCardForm;
// src/components/AddCredits.js
import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import {
  Elements,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import {
  Box,
  Button,
  TextField,
  Typography,
  Paper,
  CircularProgress,
  Alert,
  Modal,
  FormControl,
  Select,
  MenuItem,

} from '@mui/material';
import AuthWrapper from './AuthWrapper';
import { useAuth } from '../contexts/AuthContext';
import apiService from '../services/apiService';
import { formatCurrency } from '../utils/Utils';
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
const TOKEN_TO_DOLLAR_RATIO = process.env.TOKEN_TO_DOLLAR_RATIO || 5; // Default to 5 if not set


// Define the tiered pricing model
const PRICING_TIERS = [
  { minTokens: 0, discount: 0 },
  { minTokens: 1000, discount: 10 },
  { minTokens: 5000, discount: 20 },
];

// Function to calculate price based on tiered pricing
const calculatePrice = (tokens) => {
  if (isNaN(tokens) || tokens <= 0) return 0;

  let price = 0;
  let remainingTokens = tokens;

  // Sort tiers in descending order of minTokens to apply discounts from top-down
  const sortedTiers = [...PRICING_TIERS].sort((a, b) => b.minTokens - a.minTokens);

  for (const tier of sortedTiers) {
    if (remainingTokens > tier.minTokens) {
      const tierTokens = remainingTokens - tier.minTokens;
      const tierPrice = tierTokens * TOKEN_TO_DOLLAR_RATIO * (1 - tier.discount / 100);
      price += tierPrice;
      remainingTokens -= tierTokens;
    }
  }

  return formatCurrency(price);
};

const PaymentForm = ({ user, setUser, handleClose }) => {
  const stripe = useStripe();
  const elements = useElements();
  const { storedCards, getAuthHeader } = useAuth();
  const authHeader = getAuthHeader();

  const [loading, setLoading] = useState(false);
  // const [fetchingCards, setFetchingCards] = useState(true);
  const [error, setError] = useState(null);
  const [response, setResponse] = useState(null);
  const [selectedCard, setSelectedCard] = useState('new'); // Default to 'new' card
  const [amount, setAmount] = useState('');
  const [openPricingModal, setOpenPricingModal] = useState(false); // State for Pricing Modal
  const [price, setPrice] = useState(0);


  // Set the default selected card to the first card in storedCards
  useEffect(() => {
    if (storedCards.length > 0) {
      setSelectedCard(storedCards[0].id); // Set the first card's id as the default
    }
  }, [storedCards]);


  // Recalculate price whenever the amount changes
  useEffect(() => {
    const tokenAmount = parseFloat(amount);
    if (!isNaN(tokenAmount)) {
      setPrice(calculatePrice(tokenAmount));
    } else {
      setPrice(0);
    }
  }, [amount]);



  const handleSubmit = async (event) => {
    event.preventDefault();
  
    // Convert price to a numeric value
    const amountCharged = parseFloat(price.replace('$', ''))
    const amountValue = parseFloat(amount) * TOKEN_TO_DOLLAR_RATIO;
    if (isNaN(amountValue) || amountValue <= 0) {
      setError('Please enter a valid token amount.');
      return;
    }
  
    if (!stripe || !elements) return;
  
    setLoading(true);
    setError(null);
    setResponse(null);
  
    try {
      let result;
    
        const paymentIntentResponse = await apiService.createPaymentIntent(amountCharged, authHeader);
        const clientSecret = paymentIntentResponse.clientSecret;
  
        result = await stripe.confirmCardPayment(clientSecret, {
          payment_method: selectedCard,
        });
      
  
      if (result.error) {
        setError(result.error.message);
      } else if (result.paymentIntent?.status === 'succeeded') {
        const newCredits = await apiService.updateCredits(amountValue, authHeader);
        setResponse(`\nBalance: ${Math.floor(newCredits / TOKEN_TO_DOLLAR_RATIO)} Tokens`);
        setUser((prevUser) => ({ ...prevUser, credits: newCredits }));
        setTimeout(handleClose, 1000);
      } else if (result.paymentIntent?.status === 'requires_action') {
        setError('Additional authentication required. Please check your payment method.');
      } else {
        setError('Payment failed. Please try again.');
      }
    } catch (err) {
      setError(err.message || 'An unexpected error occurred.');
    } finally {
      setLoading(false);
    }
  };


  // Modal open/close handlers for Pricing structure info
  const handleOpenPricingModal = () => setOpenPricingModal(true);
  const handleClosePricingModal = () => setOpenPricingModal(false);


  return (
    <Box sx={{ p: 2 }}>
      <Paper sx={{ p: 2, mb: 0, position: 'relative' }}>
    
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 3, mb: 1 }}>
          <Typography variant="h4" gutterBottom sx={{
              fontSize: { xs: '1.5rem', sm: '2rem', md: '2rem' }, // Responsive font size
            mb:0}}>
            Add Tokens
          </Typography>
          <Button variant="outlined" onClick={handleOpenPricingModal} sx={{
              fontSize: { xs: '0.75rem', sm: '0.875rem', md: '0.875rem' }, // Responsive font size
            }}>
            View Pricing
          </Button>
        </Box>

        <form onSubmit={handleSubmit}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
        <TextField
  label="Token Amount"
  type="number"
  value={amount}
  onChange={(e) => setAmount(e.target.value)}
  fullWidth
  margin="normal"
  required
  inputProps={{
    min: '1',
    step: '1',
    
  }}
  sx={{
    '& .MuiOutlinedInput-root': {
      height: '40px', // Set the height of the input box
      padding: 0, // Remove extra padding around the input
    },
    '& .MuiOutlinedInput-input': {
      padding: '0 8px', // Ensure proper padding inside the input
      height: '40px', // Set the input's height explicitly
      lineHeight: '40px', // Align the text vertically
    },
    '& .MuiInputLabel-root': {
      transform: 'translate(14px, 10px) scale(1)', // Adjust label position
      fontSize: '14px', // Adjust label font size
      '&.Mui-focused': {
        transform: 'translate(14px, -8px) scale(0.75)', // Position label when focused
      },
    },
  }}
/>
        
<FormControl
  fullWidth
  sx={{
    mt: 0, // Adjust margin if needed
    '& .MuiInputLabel-root': {
      fontSize: '14px',
      color: '#495057', // Ensure label text color is visible
      transform: 'translate(14px, 12px) scale(1)', // Position label correctly
      '&.Mui-focused, &.MuiFormLabel-filled': {
        transform: 'translate(14px, -8px) scale(0.75)', // Move label up when focused or filled
        color: '#000', // Focused label color
      },
    },
    '& .MuiOutlinedInput-root': {
      padding: '0',
      height: '40px', // Compact height
    },
    '& .MuiSelect-select': {
      padding: '8px', // Adjust padding for dropdown text
    },
  }}
>
  <Select
    labelId="saved-cards-label"
    value={storedCards.length > 0 ? selectedCard : ''}
    onChange={(e) => setSelectedCard(e.target.value)}
    displayEmpty
    sx={{
      fontSize: { xs: '12px', sm: '14px' },
      height: '40px',
      mt: 1,
    }}
  >
    {storedCards.length === 0 ? (
      <MenuItem disabled value="">
        Add a card in settings
      </MenuItem>
    ) : (
      storedCards.map((card) => (
        <MenuItem
          key={card.id}
          value={card.id}
          sx={{
            fontSize: { xs: '12px', sm: '14px' },
          }}
        >
          {`**** **** **** ${card.card.last4} - ${String(card.card.exp_month).padStart(2, '0')}/${String(card.card.exp_year).slice(-2)}`}
        </MenuItem>
      ))
    )}
  </Select>
</FormControl>
            
          </Box>


          <Button
            variant="contained"
            type="submit"
            fullWidth
            disabled={loading}
            sx={{
              mb: 2,
              fontSize: { xs: '0.75rem', sm: '0.875rem', md: '1rem' }, // Responsive text font size
            }}
          >
            {loading ? <CircularProgress size={24} /> : `Pay ${price}`}
          </Button>

          {response && (
            <Alert severity="success" sx={{ mt: 2 }}>
              {response}
            </Alert>
          )}
          {error && (
            <Alert severity="error" sx={{ mt: 2 }}>
              {error}
            </Alert>
          )}
        </form>
      </Paper>

      {/* Pricing Modal */}
<Modal open={openPricingModal} onClose={handleClosePricingModal}>
  <Box
    sx={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      bgcolor: 'background.paper',
      boxShadow: 24,
      p: 1,
      width: '80%',
      maxWidth: '600px',
      borderRadius: '8px',
    }}
  >
<Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
  <Box display="flex" flexDirection="column" flexGrow={1} mr={2}>
    {/* <Typography variant="h5">Pricing Information</Typography> */}
    <Typography variant="body2" sx={{ fontSize: '0.7rem' }}>
      Discounts apply only to the tokens above the minimum threshold for each tier. For example, if you buy 2,000 tokens, the first 1,000 will have no discount and the next 1,000 will have a 10% discount applied.
    </Typography>
  </Box>
  {/* <IconButton onClick={handleClosePricingModal} >
    <CloseIcon />
  </IconButton> */}
</Box>
    {/* Pricing Table */}
    <Box>
      <table style={{ width: '100%', borderCollapse: 'collapse' }}>
        <thead>
          <tr>
            <th style={{ textAlign: 'left', padding: '8px', borderBottom: '1px solid #ccc' }}>Tier</th>
            <th style={{ textAlign: 'right', padding: '8px', borderBottom: '1px solid #ccc' }}>Min Tokens</th>
            <th style={{ textAlign: 'right', padding: '8px', borderBottom: '1px solid #ccc' }}>Discount</th>
            {/* <th style={{ textAlign: 'right', padding: '8px', borderBottom: '1px solid #ccc' }}>Min Spend</th> */}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style={{ textAlign: 'left', padding: '8px', borderBottom: '1px solid #eee' }}>1</td>
            <td style={{ textAlign: 'right', padding: '8px', borderBottom: '1px solid #eee' }}>0</td>
            <td style={{ textAlign: 'right', padding: '8px', borderBottom: '1px solid #eee' }}>0%</td>
            {/* <td style={{ textAlign: 'right', padding: '8px', borderBottom: '1px solid #eee' }}>$ -</td> */}
          </tr>
          <tr>
            <td style={{ textAlign: 'left', padding: '8px', borderBottom: '1px solid #eee' }}>2</td>
            <td style={{ textAlign: 'right', padding: '8px', borderBottom: '1px solid #eee' }}>1,000</td>
            <td style={{ textAlign: 'right', padding: '8px', borderBottom: '1px solid #eee' }}>10%</td>
            {/* <td style={{ textAlign: 'right', padding: '8px', borderBottom: '1px solid #eee' }}>$ 5,000</td> */}
          </tr>
          <tr>
            <td style={{ textAlign: 'left', padding: '8px', borderBottom: '1px solid #eee' }}>3</td>
            <td style={{ textAlign: 'right', padding: '8px', borderBottom: '1px solid #eee' }}>5,000</td>
            <td style={{ textAlign: 'right', padding: '8px', borderBottom: '1px solid #eee' }}>20%</td>
            {/* <td style={{ textAlign: 'right', padding: '8px', borderBottom: '1px solid #eee' }}>$ 23,000</td> */}
          </tr>
        </tbody>
      </table>
    </Box>
  </Box>
</Modal>
    </Box>
  );
};

const AddCredits = ({ user, setUser, isOpen, setIsOpen }) => {
  const handleClose = () => setIsOpen(false);

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <Box
        sx={{
          width: { xs: '100%', sm: '80%', md: '50%' },
          maxWidth: 600,
          p: 0,
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <AuthWrapper user={user}>
          <Elements stripe={stripePromise}>
            <PaymentForm user={user} setUser={setUser} handleClose={handleClose} />
          </Elements>
        </AuthWrapper>
      </Box>
    </Modal>
  );
};

export default AddCredits;
